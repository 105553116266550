const rater = require('rater-js');

export default class Rating {
  setRatings() {
    $(".rating").each((i, el) => {
      const readOnly = el.dataset.readonly  == 'true';

      rater({element: el, readOnly: readOnly, rateCallback: function rateCallback(rating, done) {
        document.getElementById('review_rating').value = rating;
        this.setRating(rating);
        done();
      }});
    });
  }
}
