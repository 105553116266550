
import 'select2/dist/css/select2.min.css'
import 'dropzone/dist/min/basic.min.css'
import 'dropzone/dist/min/dropzone.min.css'
import Dropzone from 'dropzone/dist/min/dropzone.min.js'
import Sortable from 'sortablejs';
import { DirectUpload } from 'activestorage';
import Croppie from 'croppie';
import 'croppie/croppie.css';
import 'bootstrap/js/dist/modal';

export default class DropZoneField {
  constructor(options) {
    const { target, inputTarget, thumbnails, multiple, dropzoneOptions,
            onUploaded, onRemoved, previewTemplateId, cropImages } = options;

    this.previewTemplateId = previewTemplateId;
    this.target = target;
    this.inputTarget = inputTarget;
    this.thumbnails = thumbnails;
    this.multiple = multiple;
    this.dropzoneOptions = dropzoneOptions;
    this.onUploaded = onUploaded;
    this.onRemoved = onRemoved;
    this.cropImages = cropImages || false;
  }

  attach() {
    const self = this;

    const previewTemplate = document.querySelector(`#${this.previewTemplateId}`);
    const previewTemplateHTML = previewTemplate.content.querySelector('div').outerHTML;

    let dropZoneOptions = {
      ...this.dropzoneOptions,
      addRemoveLinks: true,
      autoQueue: false,
      url: '#',
      clickable: true,
      previewTemplate: previewTemplateHTML,
      init: function() {
        this.on('addedfile', file => {
          self.handleFileAdded(this, file);
        });

        this.on('removedfile', file => {
          if(self.onRemoved) {
            self.onRemoved(file);
          }
        });

        self.displayThumbnails(this, self.thumbnails);
        self.bindSorting(this);
      },
    };

    const dropZone = new Dropzone(this.target, dropZoneOptions);
  }

  bindSorting(dropZone) {
    var sortableTarget = document.querySelector(this.target);
    var sortable = Sortable.create(sortableTarget);
  }

  displayThumbnails(dropZone, thumbnails) {
    if(thumbnails) {
      thumbnails.forEach((thumbnail) => {
        dropZone.options.addedfile.call(dropZone, thumbnail);
        dropZone.options.thumbnail.call(dropZone, thumbnail, thumbnail.url);
        dropZone.options.complete.call(dropZone, thumbnail);

        this.addInputVal(thumbnail, thumbnail.signed_id);
      });
    }
  }

  addInputVal(file, value) {
    const hiddenField = file.previewElement.querySelector('input.attachment-field');
    hiddenField.setAttribute("value", value);
  }

  handleFileAdded(dropZone, file) {
    const self = this;
    if(self.cropImages) {
      self.cropAndUploadToServer(dropZone, file);
    } else {
      self.uploadToServer(dropZone, file);
    }
  }

  uploadToServer(dropZone, file) {
    const promise = new Promise((resolve, reject) => {
      const uploadUrl = '/rails/active_storage/direct_uploads';
      const directUploadDelegate = new DirectUploadDropZoneDelegate(dropZone, file);
      const upload = new DirectUpload(file, uploadUrl, directUploadDelegate);

      const self = this;

      upload.create((error, attachment) => {
        self.addInputVal(file, attachment.signed_id);
        directUploadDelegate.directUploadDidFinish();

        if(error) {
          reject(error);
        } else {
          resolve(attachment);

          if(self.onUploaded) {
            self.onUploaded(error, attachment);
          }
        }
      });
    });

    return promise;
  }

  cropAndUploadToServer(dropZone, file) {
    const self = this;

    const modal = self.addCropModal();
    const modalSelector = $(modal);

    modalSelector.on('show.bs.modal', e => {
      const editor = modal.querySelector('.editor');
      const croppie = this.buildCroppie(editor, file);

      const cropBtn = modalSelector.find('.crop-image-button');
      cropBtn.on('click', e => {
        this.submitCrop(croppie, dropZone, file);
        modalSelector.modal('hide');
        document.body.removeChild(modal);
      });
    });

    modalSelector.modal('show');
  }

  submitCrop(croppie, dropZone, file) {
    const self = this;

    croppie.result({ type: "blob", format: 'png', size: "original"}).then(imageData => {
      const imageFile = self.copyFile(file, imageData);
      self.uploadToServer(dropZone, imageFile);
    });
  }

  buildCroppie(editor, file) {
    var croppie = new Croppie(editor, {
      viewport: { width: 320, height: 180 },
      boundary: { width: 400, height: 400 },
      showZoomer: true
    });

    croppie.bind({ url: URL.createObjectURL(file) });

    return croppie;
  }

  addCropModal() {
    const template = document.getElementById('image_cropper_template');
    const templateNode = template.content.querySelector('div').cloneNode(true);

    const modal = document.body.appendChild(templateNode);
    return modal;
  }

  copyFile(file, imageData) {
    var fileName = file.name;
    const newFileName = fileName.substr(0, fileName.lastIndexOf(".")) + ".png";

    const newFile = new File([imageData], newFileName, {
      type: 'image/png'
    });

    newFile.previewElement = file.previewElement;
    newFile.previewTemplate = file.previewTemplate;

    return newFile;
  }
}
class DirectUploadDropZoneDelegate {
  constructor(dropZone, file) {
    this.dropZone = dropZone;
    this.file = file;
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", this.directUploadDidProgress.bind(this));
  }

  directUploadDidProgress(event) {
    const { loaded, total } = event;
    const progress = (loaded / total) * 100;

    this.dropZone.emit('uploadprogress', this.file, progress, loaded);
  }

  directUploadDidFinish() {
    this.dropZone.emit('complete', this.file);
  }
}

Dropzone.autoDiscover = false;