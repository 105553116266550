import modalTemplate from  '../templates/badges_modal.mustache';
import multiModalTemplate from  '../templates/badges_multi_modal.mustache';
const Mustache = require('mustache');

class BadgesModal {
  async attach() {
    this.badges_channel = App.cable.subscriptions.create("BadgesChannel", {
      received: this.displayBadgeModal.bind(this)
    });

    const badges = await this.retrieveBadges();
    this.initializeModal(badges);
  }

  async retrieveBadges() {
    return $.get('/badges').then(data => data.badges);
  }

  initializeModal(badges) {
    const self = this;

    if(badges.length) {
      if(badges.length > 1) {
        self.displayMultipleBadgesModal(badges);
      } else {
        self.displayBadgeModal(badges[0]);
      }
    }
  }

  displayMultipleBadgesModal(badges) {
    const badgeIds = badges.map(badge => badge.badge_id);
    const modalHtml = Mustache.render(multiModalTemplate, {
      badges: badges,
      badge_ids: badgeIds
    });

    const selector = "#badges_multi_modal";

    $(selector).remove();
    $("body").append(modalHtml);
    $(selector).on('hidden.bs.modal', this.badgeMultiModalDismissed.bind(this));
    $(selector).modal('show');
  }

  displayBadgeModal(badge) {
    const modalHtml = Mustache.render(modalTemplate, {
      badge_id: badge.badge_id,
      title: badge.title,
      image_path: badge.image_path
    });

    const selector = `#badge_modal_${badge.badge_id}`;

    $(selector).remove();
    $("body").append(modalHtml);
    $(selector).on('hidden.bs.modal', this.badgeModalDismissed.bind(this));
    $(selector).modal('show');
  }

  badgeModalDismissed(e) {
    var badgeId = $(e.target).find(".badge-id").val();
    this.badges_channel.send({ type: 'badge_seen', badge_id: badgeId })
  }

  badgeMultiModalDismissed(e) {
    var badgeIds = $(e.target).find(".badge-ids").val();
    this.badges_channel.send({ type: 'badges_seen', badge_ids: badgeIds})
  }
}

export default BadgesModal;
